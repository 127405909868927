import CryptoJS from "crypto-js";

//Development
// export const URL_CLIENT = "http://localhost:3001";
// let URL_API = "http://localhost:3000";

//Production
export const URL_CLIENT = "https://riego.agrosoftweb.com";
let URL_API = "https://api.agrosoftweb.com";

const KeyCrypto = "RgUkXp2s5v8y/B?E(H+KbPeShVmYq3t6w9z$C&F)";

export const recordsPerPageSelect = 10;

export const sendPostHttp = ({ parameters, api, route }) => {
	return new Promise((resolve, reject) => {
		const url = `${URL_API}/${api}/${route}`;
		const params = {
			method: 'POST',
			body: JSON.stringify(parameters),
			mode: 'cors',
			redirect: 'follow',
			headers: new Headers({
				'Content-Type': 'application/json'
			})
		};

		const request = new Request(url, params);

		fetch(request).then(response => response.json())
			.then(response => resolve(response))
			.catch(error => reject(error.message));
	});
}

export const sendPostHttpWithToken = ({ parameters, api, route, token_access }) => {
	return new Promise((resolve, reject) => {
		const url = `${URL_API}/${api}/${route}`;
		const params = {
			method: 'POST',
			body: JSON.stringify(parameters),
			mode: 'cors',
			redirect: 'follow',
			headers: new Headers({
				'Content-Type': 'application/json',
				"Authorization": `Bearer ${token_access}`
			})
		};

		const request = new Request(url, params);

		fetch(request).then(response => response.json())
			.then(response => resolve(response))
			.catch(error => reject(error.message));
	});
}

export const sendPostHttpIndices = ({ parameters }) => {
	return new Promise((resolve, reject) => {
		const url = 'https://indices.api.agrosoftweb.com/indices';
		const params = {
			method: 'POST',
			body: JSON.stringify(parameters),
			mode: 'cors',
			redirect: 'follow',
			headers: new Headers({
				'Content-Type': 'application/json',
			})
		};

		const request = new Request(url, params);

		fetch(request).then(response => response.json())
			.then(response => resolve(response))
			.catch(error => reject(error.message));
	});
}

export const sendPutHttpWithToken = ({ parameters, api, route, token_access }) => {
	return new Promise((resolve, reject) => {
		// if(localStorage.getItem('id_aplicacion')){
		// 	let id_aplicacion = DecryptText(localStorage.getItem('id_aplicacion'));
		// 	URL_API = id_aplicacion === '496872536' ? "http://190.223.54.4:82" : URL_API;
		// }
		const url = `${URL_API}/${api}/${route}/${parameters.id}`;
		const params = {
			method: 'PUT',
			body: JSON.stringify(parameters),
			mode: 'cors',
			redirect: 'follow',
			headers: new Headers({
				'Content-Type': 'application/json',
				"Authorization": `Bearer ${token_access}`
			})
		};

		const request = new Request(url, params);

		fetch(request).then(response => response.json())
			.then(response => resolve(response))
			.catch(error => reject(error.message));
	});
}

export const sendDeleteHttpWithToken = ({ parameters, api, route, token_access }) => {
	return new Promise((resolve, reject) => {
		const url = `${URL_API}/${api}/${route}/${parameters.id}`;
		const params = {
			method: 'DELETE',
			mode: 'cors',
			redirect: 'follow',
			headers: new Headers({
				'Content-Type': 'application/json',
				"Authorization": `Bearer ${token_access}`
			})
		};

		const request = new Request(url, params);

		fetch(request).then(response => response.json())
			.then(response => {
				if (response.error) {
					reject(response.message);
				} else {
					resolve(response)
				}
			})
			.catch(error => reject(error.message));
	});
}

export const sendGetHttp = ({ api, route, token_access }) => {
	return new Promise((resolve, reject) => {
		const url = `${URL_API}/${api}/${route}`;
		const params = {
			method: 'GET',
			mode: 'cors',
			redirect: 'follow',
			headers: new Headers({
				'Content-Type': 'application/json',
				"Authorization": `Bearer ${token_access}`
			})
		};

		const request = new Request(url, params);

		fetch(request).then(response => response.json())
			.then(response => {
				if (response.error) {
					reject(response.message);
				} else {
					resolve(response)
				}
			})
			.catch(error => reject(error.message));
	})
}

export const sendGetHttpWithParameters = ({ api, route, token_access, parameters }) => {
	return new Promise((resolve, reject) => {
		const url = new URL(`${URL_API}/${api}/${route}`);
		Object.keys(parameters).forEach(key => url.searchParams.append(key, parameters[key]));

		const params = {
			method: 'GET',
			mode: 'cors',
			redirect: 'follow',
			headers: new Headers({
				'Content-Type': 'application/json',
				"Authorization": `Bearer ${token_access}`
			})
		};

		const request = new Request(url, params);

		fetch(request).then(response => response.json())
			.then(response => {
				if (response.error) {
					reject(response.message);
				} else {
					resolve(response)
				}
			})
			.catch(error => {
				reject(error.message)
			});
	})
}

export const sendGetHttpClean = () => {
	const ids = localStorage.getItem('ids_recordados') ? JSON.parse(localStorage.getItem('ids_recordados')) : [];
	localStorage.clear();
	localStorage.setItem('ids_recordados', JSON.stringify(ids));
	sessionStorage.clear();
	window.location.reload();
}

export const setLocalStorage = (list) => {
	list.forEach(lt => {
		if (Array.isArray(lt.value)) {
			localStorage.setItem(lt.name, JSON.stringify(lt.value))
		} else {
			localStorage.setItem(lt.name, lt.value);
		}
	});
}

export const validateNumber = (number_text) => {
	return /^-?[\d.]+(?:e-?\d+)?$/.test(number_text);
}

export const generateUuidv4 = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
		return v.toString(16);
	});
}

export const validateJson = (value) => {
	try {
		JSON.parse(value);
	} catch (error) {
		return false;
	}

	return true;
}

export const getDateNow = () => {
	let nFecha = new Date();

	let anio = nFecha.getFullYear(),
		mes = nFecha.getMonth() + 1,
		dia = nFecha.getDate();

	if (mes < 10) {
		mes = `0${mes}`;
	}

	if (dia < 10) {
		dia = `0${dia}`;
	}

	return `${anio}-${mes}-${dia}`;
}

export const convertStringToDateHour = (fecha) => {
	return fecha.substr(8, 2) + '/' + fecha.substr(5, 2) + '/' + fecha.substr(0, 4);
}

export const sortBy = (function () {
	var toString = Object.prototype.toString,
		// default parser function
		parse = function (x) { return x; },
		// gets the item to be sorted
		getItem = function (x) {
			var isObject = x != null && typeof x === "object";
			var isProp = isObject && this.prop in x;
			return this.parser(isProp ? x[this.prop] : x);
		};

	/**
	 * Sorts an array of elements.
	 *
	 * @param {Array} array: the collection to sort
	 * @param {Object} cfg: the configuration options
	 * @property {String}   cfg.prop: property name (if it is an Array of objects)
	 * @property {Boolean}  cfg.desc: determines whether the sort is descending
	 * @property {Function} cfg.parser: function to parse the items to expected type
	 * @return {Array}
	 */
	return function sortby(array, cfg) {
		if (!(array instanceof Array && array.length)) return [];
		if (toString.call(cfg) !== "[object Object]") cfg = {};
		if (typeof cfg.parser !== "function") cfg.parser = parse;
		cfg.desc = !!cfg.desc ? -1 : 1;
		return array.sort(function (a, b) {
			a = getItem.call(cfg, a);
			b = getItem.call(cfg, b);
			return cfg.desc * (a < b ? -1 : +(a > b));
		});
	};

}());

export const getUniqueArray = (array) => {
	return array.filter(function (el, index, arr) {
		return index === arr.indexOf(el);
	});
}

export const tableToExcel = (function () {
	var uri = 'data:application/vnd.ms-excel;base64,'
		, template =
			`<html xmlns:o="urn:schemas-microsoft-com:office:office"
        xmlns:x="urn:schemas-microsoft-com:office:excel"
        xmlns="http://www.w3.org/TR/REC-html40">
        <meta charset="utf-8"/>
        <head>
            <!--[if gte mso 9]><xml>
                <x:ExcelWorkbook>
                    <x:ExcelWorksheets>
                        <x:ExcelWorksheet>
                            <x:Name>{nombreReporte}</x:Name>
                            <x:WorksheetOptions>
                                <x:DisplayGridlines/>
                            </x:WorksheetOptions>
                        </x:ExcelWorksheet>
                    </x:ExcelWorksheets>
                </x:ExcelWorkbook>
            </xml><![endif]-->
        </head>
            <body>
                <h2 style="font-weight: bold; text-align: center;">{nombreReporte}</h2>
                <table style="border: none; font-size: 13px;">
                    <tr><td>{fundo}</td></tr>
                    <tr><td>{cultivo}</td></tr>
                    <tr><td>{fecha_reporte}</td></tr>
                </table>
                <br/>
                <table style="font-size:13px;">
                    {tabla}
                </table>
            </body>
    </html>`
		, base64 = function (s) {
			return window.btoa(unescape(encodeURIComponent(s)))
		},
		format = function (s, c) {
			return s.replace(/{(\w+)}/g, function (m, p) {
				return c[p];
			})
		}
	return function (optionsExcel) {
		//if (!table.nodeType) table = document.getElementById(table)
		//var ctx = { worksheet: name || 'Worksheet', table: table.innerHTML }
		var ctx = {
			nombreReporte: optionsExcel.nombreReporte,
			tabla: optionsExcel.tabla,
			fundo: "Fundo: " + optionsExcel.fundo,
			cultivo: "Cultivo: " + optionsExcel.cultivo,
			fecha_reporte: "Fecha: " + optionsExcel.fecha_reporte
		}

		var elementoA = document.createElement('a');
		elementoA.href = uri + base64(format(template, ctx));
		elementoA.download = `${optionsExcel.nombreReporte}.xls`;
		elementoA.target = "_blank";

		elementoA.click();
	}
})();

export const getFechaActualLetras = () => {
	var fecha = new Date();

	var diaNumero = parseInt(fecha.getDay());
	var dia = null;
	var diaDelMes = fecha.getDate();

	var mesNumero = parseInt(fecha.getMonth());
	var mes = null;

	var anio = fecha.getFullYear();


	switch (diaNumero) {
		case 0:
			dia = 'Domingo';
			break;
		case 1:
			dia = 'Lunes';
			break;
		case 2:
			dia = 'Martes';
			break;
		case 3:
			dia = 'Miércoles';
			break;
		case 4:
			dia = 'Jueves';
			break;
		case 5:
			dia = 'Viernes';
			break;
		case 6:
			dia = 'Sábado';
			break;

		default:
			break;
	}

	switch (mesNumero) {
		case 0:
			mes = 'Enero';
			break;
		case 1:
			mes = 'Febrero';
			break;
		case 2:
			mes = 'Marzo';
			break;
		case 3:
			mes = 'Abril';
			break;
		case 4:
			mes = 'Mayo';
			break;
		case 5:
			mes = 'Junio';
			break;
		case 6:
			mes = 'Julio';
			break;
		case 7:
			mes = 'Agosto';
			break;
		case 8:
			mes = 'Setiembre';
			break;
		case 9:
			mes = 'Octubre';
			break;
		case 10:
			mes = 'Noviembre';
			break;
		case 11:
			mes = 'Diciembre';
			break;

		default:
			break;
	}

	return dia + ', ' + diaDelMes + ' de ' + mes + ' de ' + anio;
}

export const EncryptText = Val => {
	try {
		return CryptoJS.AES.encrypt(Val, KeyCrypto).toString();
	}
	catch (error) {
		throw error;
	}
}

export const DecryptText = Val => {
	try {
		return CryptoJS.AES.decrypt(Val, KeyCrypto).toString(CryptoJS.enc.Utf8);
	}
	catch (error) {
		throw error;
	}
}

export const randomStringGenerate = () => {
	let chars = "ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
	let string_length = 8;
	let randomstring = "";
	let charCount = 0;
	let numCount = 0;

	for (var i = 0; i < string_length; i++) {
		if (
			(Math.floor(Math.random() * 2) === 0 && numCount < 3) ||
			charCount >= 5
		) {
			let rnum = Math.floor(Math.random() * 10);
			randomstring += rnum;
			numCount += 1;
		} else {
			let rnum = Math.floor(Math.random() * chars.length);
			randomstring += chars.substring(rnum, rnum + 1);
			charCount += 1;
		}
	}

	if (randomstring === randomstring.toUpperCase()) {
		return randomStringGenerate();
	} else if (randomstring === randomstring.toLowerCase()) {
		return randomStringGenerate();
	}

	return randomstring;
};

export const validatePassword = (passwordText) => {
	let regexValidate = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
	return regexValidate.test(passwordText);
};

export const setSelectRecorsDataTable = () => {
	// eslint-disable-next-line no-undef
	const selectDOM = $('select');

	// eslint-disable-next-line no-undef
	const label = $('label[class="mr-2"]');

	// eslint-disable-next-line no-undef
	const input = $('input[placeholder="type string..."]');

	// eslint-disable-next-line no-undef
	const filter = $(label)[0];

	// eslint-disable-next-line no-undef
	const textItems = $(label)[1];

	// eslint-disable-next-line no-undef
	$(filter).text("Filtro: ");

	// eslint-disable-next-line no-undef
	$(textItems).text("Registros por página: ");

	// eslint-disable-next-line no-undef
	$(input).attr("placeholder", "Buscar...");

	if (selectDOM) {
		const keysSelect = Object.keys(selectDOM);
		const positions = keysSelect.filter(k => k !== "length" && k !== "prevObject");
		// eslint-disable-next-line no-undef
		const itemsPerPageSelect = $(selectDOM)[positions.length - 1];
		// eslint-disable-next-line no-undef
		$(itemsPerPageSelect).val("10");
	}
}


export const calcularFechaIntervaloInicio = (fecha, intervalo, dma, separador) => {
	separador = separador || "-";
	let arrayFecha = fecha.split(separador);
	let dia = arrayFecha[2];
	let mes = arrayFecha[1];
	let anio = arrayFecha[0];

	let fechaInicial = new Date(anio, mes - 1, dia);
	let fechaFinal = fechaInicial;
	if (dma === "m" || dma === "M") {
		fechaFinal.setMonth(fechaInicial.getMonth() + parseInt(intervalo));
	} else if (dma === "y" || dma === "Y") {
		fechaFinal.setFullYear(fechaInicial.getFullYear() + parseInt(intervalo));
	} else if (dma === "d" || dma === "D") {
		fechaFinal.setDate(fechaInicial.getDate() + parseInt(intervalo));
	} else {
		return fecha;
	}

	dia = fechaFinal.getDate();
	mes = fechaFinal.getMonth() + 1;
	anio = fechaFinal.getFullYear();

	dia = (dia.toString().length === 1) ? "0" + dia.toString() : dia;
	mes = (mes.toString().length === 1) ? "0" + mes.toString() : mes;

	return anio + '-' + mes + "-" + dia;
}

export const convertirFormatoFechaGrafico = (fecha) => {
	"07/01/2021"
	return fecha.substr(6, 4) + '-' + fecha.substr(3, 2) + '-' + fecha.substr(0, 2) + 'T00:00:00.000Z'
}

export const obtenerPeriodos = (ArrayResponse = []) => {
    let Periodos = [];

    if (ArrayResponse.length > 0) {
        ArrayResponse = sortBy(ArrayResponse, { prop: "fecha" });
        ArrayResponse.map((elemento) => {
            Periodos.push(convertStringToDateHour(elemento.fecha));
        });
    }

    return Periodos;
}
